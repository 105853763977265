<template>
   <div class="page_section register_page">
      <MobileTitle :title="'Registrieren'"/>
      <div class="content_element timetable_element">
         <vue-form
            :state="formstate"
            @submit.prevent="registerForm"
            id="registerForm"
         >
            <div class="timetable_wrapper">
               <div class="timetable_inner_wrapper">
                  <div class="navigation_overlay"></div>
                  <div class="timetable_inner_inner">
                     <h5 class="timetable_title">Registrieren</h5>

                     <div class="row">
                        <div class="col-12 col-md-6">
                           <validate tabindex="1">
                              <InputSelect
                                 name="title"
                                 placeholder="Anrede"
                                 inputId="registerTitle"
                                 v-model="model.title"
                                 :options="salutationOptions"
                                 :required="true"
                                 :disabled="false"
                              />
                              <field-messages name="title" class="vf-error_message">
                                 <template slot="required" slot-scope="state">
                                    <span v-if="state.$dirty || state.$submitted">Bitte füllen Sie dieses Pflichtfeld aus.</span>
                                 </template>
                              </field-messages>
                           </validate>

                           <validate tabindex="2">
                              <InputText
                                 name="first_name"
                                 placeholder="Vorname eingeben"
                                 inputId="registerFirstName"
                                 v-model="model.firstName"
                                 :required="true"
                                 :disabled="false"
                              />
                              <field-messages name="first_name" class="vf-error_message">
                                 <template slot="required" slot-scope="state">
                                    <span v-if="state.$dirty || state.$submitted">Bitte füllen Sie dieses Pflichtfeld aus.</span>
                                 </template>
                              </field-messages>
                           </validate>

                           <validate tabindex="3">
                              <InputText
                                 name="last_name"
                                 placeholder="Nachname eingeben"
                                 inputId="registerLastName"
                                 v-model="model.lastName"
                                 :required="true"
                                 :disabled="false"
                              />
                              <field-messages name="last_name" class="vf-error_message">
                                 <template slot="required" slot-scope="state">
                                    <span v-if="state.$dirty || state.$submitted">Bitte füllen Sie dieses Pflichtfeld aus.</span>
                                 </template>
                              </field-messages>
                           </validate>
                        </div>
                        <div class="col-12 col-md-6">
                           <validate tabindex="4">
                              <InputText
                                 name="email"
                                 placeholder="E-Mail-Adresse"
                                 inputId="registerEmail"
                                 v-model="model.email"
                                 type="email"
                                 :required="true"
                                 :disabled="false"
                              />
                              <field-messages name="email" class="vf-error_message">
                                 <template slot="required" slot-scope="state">
                                    <span v-if="state.$dirty || state.$submitted">Bitte füllen Sie dieses Pflichtfeld aus.</span>
                                 </template>
                                 <template slot="email" slot-scope="state">
                                    <span
                                       v-if="!state.$valid && state.$dirty">Diese E-Mail-Adresse ist nicht gültig</span>
                                 </template>
                              </field-messages>
                           </validate>

                           <validate
                              tabindex="5"
                              :custom="{ passwordStrength: passwordStrength }"
                           >
                              <InputText
                                 name="password"
                                 placeholder="Passwort"
                                 inputId="registerPassword"
                                 v-model="model.password"
                                 type="password"
                                 :required="true"
                                 :disabled="false"
                              />
                              <field-messages name="password" class="vf-error_message">
                                 <template slot="required" slot-scope="state">
                                    <span v-if="state.$dirty || state.$submitted">Bitte füllen Sie dieses Pflichtfeld aus.</span>
                                 </template>
                                 <template slot="passwordStrength" slot-scope="state">
                        <span v-if="state.$dirty || state.$submitted">
                          Das Passwort muss Groß- und Kleinschreibung sowie mindestens eine Ziffer oder ein Sonderzeichen enthalten und mindestens 8 Zeichen lang sein
                        </span>
                                 </template>
                              </field-messages>
                           </validate>

                           <validate tabindex="5" :custom="{ matches: matches }">
                              <InputText
                                 name="passwordConfirmation"
                                 placeholder="Passwort Bestätigung"
                                 inputId="registerPasswordConfirmation"
                                 v-model="model.passwordConfirmation"
                                 type="password"
                                 :required="true"
                                 :disabled="false"
                              />
                              <field-messages
                                 name="passwordConfirmation"
                                 class="vf-error_message"
                              >
                                 <template slot="required" slot-scope="state">
                                    <span v-if="state.$dirty || state.$submitted">Bitte füllen Sie dieses Pflichtfeld aus.</span>
                                 </template>
                                 <template slot="matches" slot-scope="state">
                                    <span
                                       v-if="state.$dirty || state.$submitted">Die Passwörter stimmen nicht überein</span>
                                 </template>
                              </field-messages>
                           </validate>
                        </div>
                     </div>
                     <div class="register_links">
                        <a href="#" @click.prevent="$store.commit('globalPopup/setLoginOpen', true)">
                           Anmelden
                        </a>
                        <span class="seperator"> | </span>
                        <router-link :to="{name: 'forgotPassword'}">Passwort vergessen?</router-link>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container">
               <div class="timetable_submit_wrapper d-flex flex-column align-items-center">
                  <button class="btn btn-grey" :disabled="formSending">
                     <template v-if="formSending">Registrieren ...</template>
                     <template v-else>Registrieren</template>
                  </button>
                  <img
                     class="timetable_submit_wrapper_logo"
                     :src="assetsPath + '/abellio_logo_small.png'"
                     alt=""
                  />
               </div>
            </div>
         </vue-form>
      </div>
      <Footer/>
   </div>
</template>

<script>
   import {mapState} from "vuex";
   import {config} from "../../config";
   import MobileTitle from "../_common/MobileTitle";
   import Footer from "../_common/Footer";
   import {InputText, InputSelect} from "../../components/_form";

   export default {
      name: "Register",
      components: {
         MobileTitle,
         Footer,
         InputText,
         InputSelect,
      },
      data() {
         return {
            formstate: {},
            model: [],
            formSending: false,
            salutationOptions: [
               {
                  key: "Frau",
                  value: "Frau",
               },
               {
                  key: "Herr",
                  value: "Herr",
               },
            ],
         };
      },
      computed: {
         ...mapState(["alert"]),
         assetsPath() {
            return config.apiUrl + "/storage/app/media";
         },
      },
      methods: {
         matches: function (value) {
            if (!this.model.password) {
               return true;
            }
            return value === this.model.password;
         },
         passwordStrength(value) {
            return /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
               value
            );
         },
         registerForm() {
            if (this.formstate.$invalid) {
               document.getElementsByClassName("vf-field-invalid")[0].focus();
               return;
            }
            this.formSending = true;
            this.$store.dispatch("authentication/register", {
               title: this.model.title ? this.model.title : "",
               name: this.model.firstName ? this.model.firstName : "",
               surname: this.model.lastName ? this.model.lastName : "",
               email: this.model.email ? this.model.email : "",
               password: this.model.password
                  ? this.model.password
                  : this.model.password,
               password_confirmation: this.model.passwordConfirmation
                  ? this.model.passwordConfirmation
                  : "",
            });
         },
      },
      watch: {
         "alert.type": function (val) {
            this.formSending = false;
            if (val == "alert-success") {
               this.model = [];
               this.formstate._reset();
            }
         },
      },
   };
</script>

<style scoped lang="scss">
</style>
